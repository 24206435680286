import {useState } from "react";
import LabelContainer from "../components/LabelContainer";
import CryptoJS from 'crypto-js';

const Homepage = () => {
  // If we got Data from API call
  const [dataLoaded, setDataLoaded] = useState(false);
  // If there was an error from the API call
  const [dataLoadedError, setDataLoadedError] = useState(false);
  // Store the API response
  const [responseData, setResponseData] = useState();
  // Store Lieferantenauftrag data
  const [lieferantenauftragData, setLieferantenauftragData] = useState({});
  // Store Bestands data
  const [bestandsData, setBestandsData] = useState({});
  // Switch if we have multiple bestandsaenderungen for one Lieferantenauftrag
  const [multipleBestand, setMultipleBestand] = useState(false);
  // Store Bestandsaenderungsnummern
  const [bestandNumbers, setBestandNumbers] = useState([]);
  // Store chosen Bestandsnumber
  const [chosenBestandNumber, setchosenBestandNumber] = useState("");
  // Store the Lieferantenauftrag inputfield value
  const [lieferantenauftragInputValue, setLieferantenauftragInputValue] =
    useState("");
  // Store the Produktnummer inputfield value
  const [produktnummerInputValue, setProduktnummerInputValue] = useState("");
  // If API call got empty dataset back = the "Lieferantenauftrag" does not exist
  const [noDataFound, setNoDataFound] = useState(false);
  // Produkt / Lieferantenauftrag switch
  const [isProdukt, setIsProdukt] = useState(false);

  const GetEncryptedKey = () => {
    const secretKey = process.env.REACT_APP_CYPHER_KEY; // Ensure this is secure and 256 bits!
    const key = process.env.REACT_APP_API_KEY;

    const encryptedText = CryptoJS.AES.encrypt(key, secretKey).toString();    
    return encryptedText;
  }

  

  // Reset input values
  const resetLieferantenauftragInput = () => {
    setLieferantenauftragInputValue("");
  };
  const resetProduktnummerInput = () => {
    setProduktnummerInputValue("");
  };

  // Functions to monitor the inputfield values
  const handleChangeLieferantenauftragInput = (event) => {
    setLieferantenauftragInputValue(event.target.value);
  };
  const handleChangeProduktInput = (event) => {
    setProduktnummerInputValue(event.target.value);
  };

  // API call
  const GetLieferantenauftrag = async () => {
    // Get error text-container
    var errorTextContainer = document.getElementById("userMessageContainter");
    // If we missing userinput, return and display error
    if(lieferantenauftragInputValue == "")
    {
      errorTextContainer.innerText = "Bitte geben Sie eine Lieferantenauftragsnummer ein.";
      return;
    }
    else{errorTextContainer.innerText = "";}

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "Lieferantenauftrag/" +
          lieferantenauftragInputValue,
        {
          method: "GET",
          headers:{
            'key' : GetEncryptedKey(),
          }
        }
      );
      
      // Store and split response data
      const collmexResponse = await response.json();
      const auftragResponse = collmexResponse[0];
      const bestandResponse = collmexResponse[1];

      // Check if we have no entry in the data
      if (Object.keys(auftragResponse).length < 1) {
        setNoDataFound(true);
        setDataLoaded(false);
        setMultipleBestand(false);
      } else {
        setResponseData(collmexResponse);
        setLieferantenauftragData(auftragResponse);
        setBestandsData(bestandResponse);        
        setDataLoaded(true);
        setNoDataFound(false);
        setDataLoadedError(false);
        setIsProdukt(false);

        async function EncryptMessage(message, secretKey) {
          const encoder = new TextEncoder();
          const data = encoder.encode(message);
      
          // Convert the secret key from a text string to a format usable by the Web Crypto API
          const keyMaterial = await window.crypto.subtle.importKey(
              "raw",
              encoder.encode(secretKey),
              { name: "AES-GCM" },
              false,
              ["encrypt"]
          );
      
          // Create an initialization vector (IV)
          const iv = window.crypto.getRandomValues(new Uint8Array(12));
      
          // Encrypt the message
          const encrypted = await window.crypto.subtle.encrypt(
              { name: "AES-GCM", iv: iv },
              keyMaterial,
              data
          );

      
          return {
              iv: Array.from(iv),
              encryptedData: Array.from(new Uint8Array(encrypted))
          };
      }



        /* Filter unique bestandsaenderungsnummern */
        const allBestandNr = () => {
          const returnMe = [];
          for (var i = 0; i < Object.keys(bestandResponse).length; i++) {
            if (!returnMe.includes(bestandResponse[i].Bestandsänderung_Nr)) {
              returnMe.push(bestandResponse[i].Bestandsänderung_Nr);
            }
          }
          return returnMe;
        };

        /* Get only the Bestandsaenderungs count */
        const bestandCount = allBestandNr();

        /* Check for unique bestandsaenderungen count */
        if (bestandCount.length > 0) // Multiple
        {
          setMultipleBestand(true);
          setBestandNumbers(bestandCount);
        }
        else if(bestandCount.length = 1) // One
        {
          setMultipleBestand(false); 
          if(bestandCount[0] != null)
          {
            setchosenBestandNumber(bestandCount[0]);
          }
          else 
          {
            setchosenBestandNumber("-");
          }
        }
         else // None
        {
          setchosenBestandNumber("-");
        }
      }
    } catch (err) {
      // Error handling
      console.log("Error occured:" + err);
      setResponseData();
      setDataLoadedError(true);
      setDataLoaded(false);
    }
  };

  // API-Call to get one product
  const GetProdukt = async () => {
    // Clean up all / in userinput
    let cleanedProduktnummerInputValue = produktnummerInputValue.replace(/\//g, "sL4Sh");

    // Check userinput
    var errorTextContainer = document.getElementById("userMessageContainter");
    if(cleanedProduktnummerInputValue == "")
    {
      errorTextContainer.innerText = "Bitte geben Sie eine Produktnummer ein.";
      return;
    }
    else{errorTextContainer.innerText = "";}

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "Produkt/" + cleanedProduktnummerInputValue,
        {
          method: "GET",
          headers:{
            'key' : GetEncryptedKey(),
          }
        }
      );

      const collmexResponse = await response.json();
      // Check if we got data back
      if (Object.keys(collmexResponse).length < 1)  // No Data
      {
        setNoDataFound(true);
        setDataLoadedError(false);
        setDataLoaded(false);

      } else // Data found
      {
        setResponseData(collmexResponse);
        setDataLoaded(true);
        setNoDataFound(false);
        setDataLoadedError(false);
        setIsProdukt(true);
        setMultipleBestand(false);
      }
    } catch (err) {
      // Error handling
      console.log("Error occured:" + err);
      setResponseData();
      setDataLoadedError(true);
      setDataLoaded(false);
    }
  };

  /* Function - Filter Auftragsdata on user choice */
  const FilterBestandToPrint = (number) => {
    /* Empty, new object */
    const filteredObject = {};
    /* Counter for unique keys  */
    var counter = 0;

    /* Iterate over the whole bestandsdata */
    Object.values(bestandsData).forEach((bestandEntry) => {     

      /* If bestandsaenderung equals users choice */
      if (bestandEntry["Bestandsänderung_Nr"] == number) {
        setchosenBestandNumber(bestandEntry["Bestandsänderung_Nr"]);
       
        /* Get the Produktnummer that this entry holds */
        const targetProduktnummer = bestandEntry["Produktnummer"];
        

        /* Loop to check lieferantenData for searched produktnummer */
        for (const key in lieferantenauftragData) {
          /* If the object has this property */
          if (Object.hasOwnProperty.call(lieferantenauftragData, key)) {
            /* Get Produktnummer in current LIeferantenauftrag entry */
            const currentProduktnummer =
              lieferantenauftragData[key].Produktnummer;
            // Check if current Produktnummer matches the target value
            if (currentProduktnummer === targetProduktnummer) {
              /* For the value in "Menge" of the Bestandaendrungs entry, add objects to the filtered Object */
              filteredObject[counter] = { ...lieferantenauftragData[key] };
              counter++;
            }
          }
        }
      }
    });
    /* Reset the flag for multiple bestand */
    setMultipleBestand(false);
    /* Insert the newly filtered Object into the hook to render it out */
   
    responseData[0] = filteredObject;
  };

  // Function to mutate the response data, to include one entry for each .Menge value. If the bestnadaenderung has a value of 7, we need 7 seperate object entrys
  const MutateMengeToCount = (mutateMe) =>
  {
    
    const returnMe = {};
    var counter = 0;
    for(const key in mutateMe)
    {
      for(var i = 0; i < mutateMe[key].Menge;i++)
      {
        returnMe[counter] = { ...mutateMe[key] };
        counter++;
      }
    }    
    return returnMe;
  }

  return (
    <>
      {/* General */}
      <h2 className="text-3xl pt-3 font-bold">Collmex Label Drucker</h2>
      <h2 className="text-xs pt-1 pb-3">Created by: Shop Operator Namibia</h2>
      <h2 className="text-s">• Bitte geben Sie eine Lieferantenauftragsnummer oder eine Produktnummer ein.</h2>
      <h2 className="text-s">• Wählen sie ggf. die korrekte Bestandsänderung.</h2>
      <h2 className="text-s">• Erzeugen Sie die Label im PDF Format.</h2>
      <input
        type="number"
        value={lieferantenauftragInputValue}
        onChange={handleChangeLieferantenauftragInput}
        placeholder="Lieferungsnummer"
        className="bg-blue-100 my-4 me-3 p-2 min-w-64"
      ></input>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white my-4 me-3 p-2 min-w-64"
        onClick={() => GetLieferantenauftrag()}
      >
        Lieferantenauftrag suchen
      </button>
      <br></br>
      <input
        value={produktnummerInputValue}
        onChange={handleChangeProduktInput}
        placeholder="Produktnummer"
        className="bg-blue-100 my-2 me-3 p-2 min-w-64"
      ></input>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white my-2 me-3 p-2 min-w-64"
        onClick={() => GetProdukt()}
      >
        Produkt suchen
      </button>
      <h2 style={{color: "red"}} id="userMessageContainter"></h2>
      {/* No data found */}
      {noDataFound ? (
         <h2 className="text-m">
          Diese Anfrage ist unbekannt oder enthält keine Daten.
          Stellen Sie sicher, dass der gesuchte Datensatz in Collmex eingepflegt wurde.
        </h2>
      ) : (
        <></>
      )}

      {/* Data found */}
      {dataLoaded ? (
        <div>
          {isProdukt ? (
            /* Data found & is single product */
            <>
              <h2 className="text-xl">Produktsuche</h2>
              <LabelContainer
                dataSet={responseData}
                isProdukt={isProdukt}
                resetLieferantenauftragInput={resetLieferantenauftragInput}
                resetProduktnummerInput={resetProduktnummerInput}
              />
            </>
          ) : (
            /* Data found & is full Lieferantenauftrag */
            <>
              {multipleBestand ? (
                /* If we have multiple bestandsaenderungen */
                <>                  
                  <h2 className="text-xl">Bitte wählen Sie aus, welche Bestandsänderung Sie ausdrucken wollen.</h2>
                  {/* Generate buttons for all Bestandsnummern */}
                  {bestandNumbers.map((number, index) => (
                    <>
                      <button
                        onClick={() => FilterBestandToPrint(number)}
                        className="bg-green-700 hover:bg-blue-900 text-white my-4 me-3 p-2 min-w-64"
                        value={{ number }}
                      >
                        {number}
                      </button>
                      <br></br>
                    </>
                  ))}
                </>
              ) : (
                /* If we have one Bestandsaenderung */
                <>
                  <h2 className="text-xl">Auftragssuche</h2>
                  {/* Render LabelContainer with given data */}
                  <LabelContainer
                    dataSet={MutateMengeToCount(responseData[0])}
                    bestandNr={chosenBestandNumber}
                    isProdukt={isProdukt}
                    resetLieferantenauftragInput={resetLieferantenauftragInput}
                    resetProduktnummerInput={resetProduktnummerInput}
                  />
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      {/* API call error */}
      {dataLoadedError ? (
         <h3 className="text-xl">
          Es ist ein Problem aufgetreten. Bitte stellen Sie sicher, dass Ihre Eingabe korrekt ist.

          Falls das Problem bestehen bleibt, informieren Sie bitte Ihren Administrator.
        </h3>
      ) : (
        <></>
      )}
      
    </>
  );
};

export default Homepage;
