import { maxBezeichnungCharCount } from "../utils/pdfSettings";
import LabelComponent from "./LabelComponent";

import {
  maxItemCount,
  pdfOptions,
  labelHeight,
  labelWidth,
  labelSizeUnit,
} from "../utils/pdfSettings";
import generatePDF from "react-to-pdf";

// Get HTML element to print
const getTargetElement = () => document.getElementById("print-this-container");

function limitString(str) {
  if(str != undefined)
  {
    return str.length > maxBezeichnungCharCount ? str.substring(0, maxBezeichnungCharCount - 3) + "..." : str;
  }
  else
  {
    return "*Keine Beschreibung*"
  }
  
}

// Print function
const printAll = (dataSet) => {
  generatePDF(getTargetElement, pdfOptions);
};

const LabelContainer = ({
  dataSet,
  bestandNr,
  isProdukt,
  resetLieferantenauftragInput,
  resetProduktnummerInput,
}) => {
  
  return (
    <>    
      {!isProdukt && Object.keys(dataSet).length > maxItemCount ? (
        // If datacount exceeds set maximum
        <>
          <h2 className="text-xl">
            Dieser Lieferantenauftrag übersteigt die maximale Anzahl von
            Positionen von {maxItemCount}. Bitte nutzen Sie die
            Produktnummern, um einzelne Etiketten zu erstellen.
          </h2>
        </>
      ) : (
        /* If Datacount is valid */
        <>
          <button className="bg-green-700 hover:bg-green-900 text-white my-4 me-3 p-2 min-w-64" onClick={() => printAll(dataSet)}>
            PDF erzeugen
          </button>
          {/* Div that contains items to be printed - Width is set in settings, height is dynamically calculated depending on the count of items */}
          <div
            id="print-this-container"
            style={{
              width: labelWidth + labelSizeUnit,
              height:
                toString(Object.entries(dataSet).length * labelHeight) + "mm",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {/* If datacount is valid + we do not have a product */}
            {!isProdukt ? (
              <>              
                {/* Map over Object and return a LabelComponent for every entry */}
                {Object.entries(dataSet).map(([key, value]) => (
                  <LabelComponent
                    LabelEntryOne={limitString(dataSet[key].Produktbeschreibung)}
                    LabelEntryTwo={dataSet[key].Produktnummer}
                    LabelEntryThree={dataSet[key].Lieferantenauftragsnummer + " / " + bestandNr}
                  />
                ))}
              </>
            ) : (
              /* If we have a Produkt */

              <>
                {/* Return produkt label */}
                <LabelComponent
                  LabelEntryOne={limitString(dataSet.Bezeichnung_)}
                  LabelEntryTwo={ dataSet.Produktnummer}
                  LabelEntryThree={"- / -"}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LabelContainer;
