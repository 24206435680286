import { labelHeight, labelWidth, labelSizeUnit } from "../utils/pdfSettings";
import JsBarcode from 'react-jsbarcode';

const LabelComponent = ({ LabelEntryOne, LabelEntryTwo, LabelEntryThree }) => {
  return (
    <>
      {/* Container for the label-value has to have a slightly lower height, otherwise one label occupies 2 pages */}
      <div
        name="product-info-container"
        id="item-to-print"
        style={{
          width: labelWidth + labelSizeUnit,
          height: labelHeight - 0.01 + labelSizeUnit,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <div
          className="grid grid-cols-3 p-3"
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <p className="text-xs col-span-3" style={{wordBreak: 'break-all',}}>{LabelEntryOne}</p>
          <p className="text-xs font-bold col-span-2">{LabelEntryTwo}</p>
          <p className="text-xs col-span-3 pt-1"><JsBarcode value={LabelEntryTwo} options={{ format: "CODE128", height: 10, width:1, displayValue: false, margin: 1 }} /></p>
          <p className="text-xs col-span-2">{LabelEntryThree}</p>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default LabelComponent;
