import { Resolution, Margin } from 'react-to-pdf';

export const maxBezeichnungCharCount = 100;
export const labelWidth = 57.15;
export const labelHeight = 31.75;
export const labelSizeUnit = "mm" // possible: "mm", "cm", "in" (inch) + all available CSS units
export const maxItemCount = 50; // Maximum count of items that can be processed by this Website - Too many items can cause the pdf generation to break. Workaround is in the making


export const pdfOptions = {
    // default is `save`
    method: 'save',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM = 3,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: Margin.NONE = 0,
       // default is 'A4'
       format: [labelHeight,labelWidth],
       // default is 'portrait'
       orientation: 'landscape',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'image/jpeg',
       qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
       pdf: {
          compress: true
       },
       // see https://html2canvas.hertzen.com/configuration for more options
       canvas: {
          useCORS: true
       }
    },
 };
 