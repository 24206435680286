import logo from './logo.svg';
import './App.css';
import './index.css';
import Homepage from './Scenes/HomePage';
import {useState } from "react";



function App() {
  const [valdidPW, setvaldidPW] = useState(false);
  const [pwInputValue, setPwInputValue] = useState('');

  const CheckPW = (inputPW) =>
  {    
    if(inputPW == process.env.REACT_APP_PW)
    {
      setvaldidPW(true);
    }
  }

  const handleChangePwInput = (event) => {
    setPwInputValue(event.target.value);
  };

  return (
    <div className="App container mx-auto p-6">      
    {valdidPW ? <Homepage/> 
    :
    <>
    <h1>Bitte geben Sie das Kennwort ein.</h1>
    <input
        type="password"
        value={pwInputValue}
        onChange={handleChangePwInput}
        placeholder="Passwort"
        className="bg-blue-100 my-4 me-3 p-2 min-w-64"
      ></input>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white my-4 me-3 p-2 min-w-64"
        onClick={() => CheckPW(pwInputValue)}
      >
        Login
      </button>
    </>
    }
      
    </div>
  );
}

export default App;